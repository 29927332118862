import { BsChatLeft } from "react-icons/bs";
import { IoSend } from "react-icons/io5";
import { useEffect, useState, useMemo } from "react";
import "../../../scss/whatsapp.scss";
import { useParams } from "react-router-dom";
import instance from "../../../services/axiosConfig";
import Lodash from "lodash";
import {
  Button,
  Col,
  Container,
  Navbar,
  Nav,
  Row,
  NavDropdown,
} from "react-bootstrap";

const ChatInterface = () => {
  const [conversations, setConversations] = useState([]);
  const [currentConversationId, setCurrentConversationId] = useState(null);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const currentConversation = useMemo(() => {
    return conversations.find(({ id }) => {
      return id === currentConversationId;
    });
  }, [currentConversationId, conversations]);

  const setMessagesPerConversation = (conversationId, messagesToAppend) => {
    const newMessages = { ...messages };
    newMessages[conversationId] = messagesToAppend;
    setMessages(newMessages);
  };

  const fetchConversations = async () => {
    const url = `/v1/whatsapp/${id}/contacts`;
    const res = await instance.get(url);
    const conversations = res.data.results.map(
      ({ whatsapp_id, profile_name, id, last_message }) => {
        let { content: lastMessage, timestamp } = last_message;
        lastMessage = lastMessage || "";
        return {
          id,
          whatsappId: whatsapp_id,
          name: profile_name,
          lastMessage:
            lastMessage.slice(0, 30) + (lastMessage.length > 30 ? "..." : ""),
          timestamp: new Date(timestamp),
        };
      },
    );
    setConversations(conversations);
  };
  const { id } = useParams();

  const fetchMessages = async (conversationId) => {
    console.log("Starting to fetch messages", conversationId);
    const url = `/v1/whatsapp/${id}/messages?contact__ext_id=${conversationId}`;
    const res = await instance.get(url);
    const newMessages = res.data.results.map(
      ({
        id,
        seller_visible_text,
        seller_visible_note,
        direction,
        created_at,
        ...singleResult
      }) => {
        return {
          id,
          note: seller_visible_note,
          text: seller_visible_text,
          sent: direction !== "r",
          isAutomatic: direction === "s",
          timestamp: new Date(created_at),
        };
      },
    );
    const sortedMessages = Lodash.sortBy(newMessages, (obj) => {
      return obj.timestamp;
    });
    setMessagesPerConversation(conversationId, sortedMessages);
  };

  useEffect(() => {
    fetchConversations();
  }, []);

  useEffect(async () => {
    await fetchMessages(currentConversationId);
  }, [currentConversationId]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (currentConversation) {
        fetchMessages(currentConversationId);
      }
    }, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, [currentConversationId]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!newMessage.trim()) return;
    const url = `/v1/whatsapp/${id}/messages`;
    const res = await instance.post(url, {
      contact: currentConversationId,
      text: newMessage,
    });
    const newMsg = {
      id: messages.length + 1,
      text: newMessage,
      isAutomatic: false,
      sent: true,
      timestamp: new Date(),
    };
    setMessagesPerConversation(currentConversationId, [...messages, newMsg]);
    setNewMessage("");
  };

  return (
    <div className="chat-container">
      <div className="chatsidebar">
        <div className="chatsidebar__header">
          <h2>Conversations</h2>
        </div>
        <div className="chatsidebar__list">
          {conversations.map((conv) => (
            <div
              key={conv.id}
              className={`conversation-item ${
                currentConversation?.id === conv.id
                  ? "conversation-item--active"
                  : ""
              }`}
              onClick={() => {
                setCurrentConversationId(conv.id);
              }}
            >
              <div className="conversation-item__content">
                <BsChatLeft className="w-8 h-8 text-gray-400" />
                <div className="conversation-item__details">
                  <h4>{conv.name}</h4>
                  <p className="text-sm text-gray-500 truncate">
                    {conv.lastMessage}
                  </p>
                </div>
                <span className="conversation-item__time">
                  {conv.timestamp.toLocaleString("en-GB", { timeZone: "UTC" })}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="chat-main">
        {currentConversationId ? (
          <>
            <div className="chat-main__header">
              <h2 className="text-xl font-semibold">
                {currentConversation.name}
              </h2>
            </div>

            <div className="chat-main__messages">
              {messages[currentConversationId]?.map((message) => (
                <div
                  key={message.id}
                  className={`message ${message.sent ? "message--sent" : "message--received"}`}
                >
                  <div
                    className={`message__bubble message__bubble--${message.sent ? "sent" : "received"}`}
                  >
                    {message.note ? (
                      <p className="message__time">{message.note}</p>
                    ) : null}
                    <p>{message.text}</p>
                    <p className="message__time">
                      {message.timestamp.toLocaleString("en-GB", {
                        timeZone: "UTC",
                      })}
                    </p>
                  </div>
                </div>
              ))}
            </div>

            <form
              onSubmit={handleSendMessage}
              className="chat-main__input-container container"
            >
              <Row>
                <Col md={10}>
                  <input
                    type="text"
                    value={newMessage}
                    onChange={(e) => setNewMessage(e.target.value)}
                    placeholder="Type a message..."
                  />
                </Col>
                <Col md={2}>
                  <button type="submit">
                    <IoSend className="w-5 h-5" />
                  </button>
                </Col>
              </Row>
            </form>
          </>
        ) : (
          <div className="chat-main__empty">
            Select a conversation to start chatting
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatInterface;
