export const LANDING = "/";
export const SIGN_UP = "/signup";
export const SIGN_IN = "/signin";
export const VERIFY_EMAIL = "/verify-email";
export const CONTACT_US = "/contact-us";
export const HOME = "/home";
export const ACCOUNT = "/account";
export const TERMS = "/terms";
export const INVITES = "/invites";
export const PRIVACY = "/privacy";
export const TRACKING = "/tracking";
export const CARE_PACKAGE = "/carepackage";
export const ADMIN = "/admin";
export const PASSWORD_FORGET = "/pw-forget";
export const UPDATE_PASSWORD = "/update-password";
export const SECRET_SANTA = [
  "/giftexchange",
  "/giftsexchange",
  "/gift-exchange",
  "/gifts-exchange",
  "/gifts_exchange",
  "/gift_exchange",
  "/gift",
  "/gifts",
  "/secretsanta",
  "/secret-santa",
  "/gifts/faq",
];
export const TEAM_GIFTS = ["/team-gifts", "/corporate-gifts", "/work-gifts"];
export const GIFTS = "/gifts";
export const LABEL = "/label";
export const CREATE = "/create";
export const LABELLIST = "/labellist";
export const DOWNLOAD = "/download";
export const DASHBOARD = "/dashboard";
export const ORDERS = "/orders";
export const MANIFESTS = "/manifests";
export const ADD_LABELS = "/add-labels";

export const ADDRESS_LIST = HOME + ACCOUNT + "/address-list";
export const ADDRESS_FORM = HOME + ACCOUNT + "/address-form";
export const CARD_LIST = HOME + ACCOUNT + "/card-list";

export const ACCOUNT_DETAILS = HOME + ACCOUNT + "/account-details";
export const API = HOME + ACCOUNT + "/api";

export const MORE_API = "/more/api";
export const MORE_SHOPIFY = "/more/shopify";
export const MORE_SHOP = "/more/shop-integrations";

export const ACCOUNT_FORMS = "/account-details";
export const UPDATE_ACCOUNT_DETAILS = ACCOUNT_FORMS + "/update-account-details";
export const BANK_ACCOUNT_DETAILS = ACCOUNT_FORMS + "/update-bank-details";
export const BUSINESS_REGISTRATION = ACCOUNT_FORMS + "/business-registration";
export const TAX_INFO = ACCOUNT_FORMS + "/tax-info";

export const HELP_CENTER = HOME + "/help-center";
export const WELCOME_TOUR = HELP_CENTER + "/welcome-tour";
export const ADMIN_SIGNIN = "/admin-signin";

export const INTRO = "/intro";
export const INTRO_FAQ = INTRO + "/faqs";

export const STORES = "/stores";
export const CHECKOUTS_DETAILS = HOME + STORES + "/details";
export const WHATSAPP_CONVERSATION = HOME + STORES + "/whatsapp";
export const CHECKOUTS_CREATE = CHECKOUTS_DETAILS + "/create";
export const CHECKOUTS_SETTINGS = CHECKOUTS_DETAILS + "/settings";
export const CHECKOUTS_PRODUCTS = CHECKOUTS_DETAILS + "/products";
export const CHECKOUTS_PRODUCTS_DETAIL = CHECKOUTS_PRODUCTS + "/detail";
export const CHECKOUTS_PRODUCTS_CREATE = CHECKOUTS_PRODUCTS + "/create";
export const SHOPIFY_CONNECT = HOME + STORES + "/shopify-connect";
export const SHOPIFY_CONNECT_ALT = HOME + STORES + "/shopify-connect-alternate";

export const CSV_IMPORT_PAGE = HOME + ORDERS + "/csv-import";

export const WALLETS = "/wallets";
