import React, { Component } from "react";
import { Card, Dropdown } from "react-bootstrap";
import { connect } from "react-redux";
import {
  BILLED_MANUALLY,
  CARD_PAY,
  PAYSTACK,
  PURCHASE_LABEL,
  PURCHASE_LABEL_ERROR,
} from "../../../constants/types";
import CardForm from "../UserDetails/CardForm";
import CardListItem from "../UserDetails/CardListItem";
import LabelSummary from "./LabelSummary";
import {
  selectCard,
  getUserDetails,
  purchaseRateCard,
  updatePurchaseEmail,
  updateFileType,
  checkPendingRatePurchase,
  clearPurchaseStates,
  getAccounts,
} from "../../actions";
import { applyMiddleware as dispatch } from "redux";
import FILETYPES from "../../../assets/file-types.json";
import { withRouter } from "react-router-dom";
import { clearApplicationContext } from "../../actions/ApplicationContextActions";
import MessageBlock from "../common/MessageBlock";
import { FiAlertCircle } from "react-icons/fi";
import CURRENCY_UNITS from "../../../assets/currency-units.json";
import * as ROUTES from "../../../constants/routes";

class PurchaseLabel extends Component {
  state = {
    create: false,
    collection_amount: this.props.selectedOrder?.amount_remaining
      ? this.props.selectedOrder?.amount_remaining
      : 0,
    collection_currency: "KES",
    allowAnyBalance:
      this.props.accounts.find(
        (account) => account.currency === this.props.selectedRate.currency,
      )?.allow_any_balance ?? false,
  };

  componentDidMount = () => {
    this.props.getAccounts();

    this.props.updatePurchaseEmail(this.props.info?.email || "");
    if (!this.props.paymentCard && this.props.defaultCard) {
      this.props.selectCard(this.props.defaultCard, CARD_PAY, () => {
        return;
      });
    }
    if (this.props.paymentCard) {
      const card = this.props.cardList?.find(
        (card) => card.id === this.props.paymentCard.id,
      );
      const defaultCard = this.props.cardList?.find(
        (card) => card.is_default === true,
      );
      if (!card) {
        this.props.selectCard(null, CARD_PAY);
      }
      if (this.props.defaultCard?.id !== this.props.paymentCard?.id) {
        this.props.selectCard(defaultCard, CARD_PAY);
      }
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.paymentCard !== this.props.paymentCard ||
      prevProps.fileType !== this.props.fileType ||
      prevState.collection_amount !== this.state.collection_amount ||
      prevState.collection_currency !== this.state.collection_currency
    ) {
      this.props.clearPurchaseStates();
    }

    if (prevProps.accounts !== this.props.accounts) {
      this.setState({
        allowAnyBalance:
          this.props.accounts.find(
            (account) => account.currency === this.props.selectedRate.currency,
          )?.allow_any_balance ?? false,
      });
    }
  }

  componentWillUnmount() {
    this.props.clearPurchaseStates();
  }

  toggleCreate = () => {
    this.setState({ create: !this.state.create });
  };

  validateEmail() {
    dispatch({ type: PURCHASE_LABEL });
    const re = /\S+@\S+\.\S+/;
    const valid = re.test(this.props.userEmail);
    if (!valid) {
      dispatch({
        type: PURCHASE_LABEL_ERROR,
        payload: { errors: "Enter a valid email" },
      });
    }
    return valid;
  }

  async payWithCard() {
    if (!this.validateEmail()) {
      return;
    }
    // Get the token from the response, and send to your server
    // call the back end
    const giftExchangeId =
      this.props.giftExchange && this.props.selectedExchange
        ? this.props.selectedExchange.id
        : "";
    const order_id = this.props.selectedOrder?.external_id || null;
    const amount_to_be_collected = this.state.collection_amount
      ? this.state.collection_amount
      : null;
    const payment_method_type = this.state.allowAnyBalance
      ? BILLED_MANUALLY
      : this.props.paymentCard
        ? this.props.paymentCard.payment_method_type
        : "card";
    if (!!this.props.pendingRatePurchase) {
      this.props.checkPendingRatePurchase(
        this.props.pendingRatePurchase,
        payment_method_type,
        () => {
          this.props.history.replace(this.props.returnURL);
          this.props.clearApplicationContext();
        },
      );
    } else {
      this.props.purchaseRateCard(
        {
          rate: this.props.selectedRate,
          email: this.props.userEmail,
          promo: undefined,
          method: payment_method_type,
          giftExchangeId: giftExchangeId,
          file_type: this.props.fileType,
          order_id,
          amount_to_be_collected,
        },
        this.props.paymentCard,
        this.state.allowAnyBalance,
        (label) => {
          let searchParam = "";
          if (label) {
            searchParam = `?search=${label.tracking_number || label.id}`;
          }
          this.props.history.replace(
            `${ROUTES.HOME + ROUTES.LABEL}/${searchParam}`,
          );
          this.props.clearApplicationContext();
        },
      );
    }
  }

  getButtonText = () => {
    if (!!this.props.pendingRatePurchase) {
      return "Retry";
    }
    if (this.state.allowAnyBalance) {
      return "Generate Label";
    }
    if (!this.props.paymentCard) {
      return "Select a payment method";
    }
    if (this.props.paymentCard?.provider === PAYSTACK) {
      return "Pay with Mobile Money";
    }
    return "Pay with Card";
  };

  renderFileTypes = () => {
    const list = Object.keys(FILETYPES).map((item, idx) => {
      return (
        <Dropdown.Item
          key={idx}
          onClick={() => {
            this.props.updateFileType(item);
          }}
        >
          {FILETYPES[item]}
        </Dropdown.Item>
      );
    });

    return (
      <Dropdown>
        <Dropdown.Toggle className="col-12 p-3 d-flex flex-row justify-content-between align-items-center">
          {FILETYPES[this.props.fileType]}
        </Dropdown.Toggle>
        <Dropdown.Menu>{list}</Dropdown.Menu>
      </Dropdown>
    );
  };

  renderCard = (card) => {
    if (this.state.create) {
      return (
        <CardForm
          onFinish={(id) => {
            let card = this.props.cardList.find((card) => card.id === id);
            this.props.selectCard(card, CARD_PAY, () => this.toggleCreate());
          }}
          cancleButton={
            <button className="ml-3" onClick={() => this.toggleCreate()}>
              Cancel
            </button>
          }
        />
      );
    } else {
      return (
        <Dropdown>
          <CardListItem
            card={card}
            type={CARD_PAY}
            onCreate={() => this.toggleCreate()}
            onToggle={() => this.props.getUserDetails()}
          />
          <Dropdown.Menu className="card-list col-12 p-0">
            {!this.props.loadCardList ? (
              this.props.cardList.map((item, idx) => {
                return (
                  <Dropdown.Item className="m-0 p-0" key={item.id}>
                    <CardListItem
                      card={item}
                      key={idx}
                      type={CARD_PAY}
                      select
                    />
                  </Dropdown.Item>
                );
              })
            ) : (
              <div className="spinner-border text-dark m-5" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            )}
          </Dropdown.Menu>
        </Dropdown>
      );
    }
  };

  render() {
    let card = this.props.paymentCard;
    return (
      <div className="modal d-flex zindex-modal justify-content-center justify-content-lg-start aligin-items-center">
        <div
          onClick={() => this.props.toggleModal()}
          className="backdrop"
        ></div>
        <Card className="modal-content purchase-label d-block col-11 col-lg-7 col-xl-5 m-auto ">
          <div className="col-12 p-0 d-flex flex-row justify-content-between">
            <h5>Purchse Label</h5>
            <a className="d-flex" onClick={() => this.props.toggleModal()}>
              Close
            </a>
          </div>
          <LabelSummary showCarriers={this.props.showCarriers} />
          <Card className="my-3 p-3">
            <p>
              This is the email where you'll receive a receipt and all other
              information about your shipping label.
            </p>
            <p className="email">{this.props.userEmail}</p>
          </Card>
          <Card className="filetypes my-3 p-3">
            <p>Select the type of file you what to print for the label</p>
            {this.renderFileTypes()}
          </Card>
          {this.props.selectedRate?.service?.provider
            ?.toString()
            .toLowerCase() === "shipshap" && (
            <Card className="collection-amount my-3 p-3">
              <p>Amount to be collected on delivery</p>
              <div
                className={
                  "d-flex flex-row justify-content-start align-items-center"
                }
              >
                <input
                  type={"number"}
                  onWheel={(e) => {
                    e.target.blur();
                  }}
                  onScroll={(e) => {
                    e.target.blur();
                  }}
                  min={0}
                  value={this.state.collection_amount}
                  onChange={(e) => {
                    this.setState({
                      collection_amount: parseFloat(e.target.value),
                    });
                  }}
                />
                <Dropdown>
                  <Dropdown.Toggle
                    className="col-12 p-0 px-3 d-flex flex-row justify-content-between align-items-center"
                    disabled
                  >
                    {this.state.collection_currency}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {Object.keys(CURRENCY_UNITS).map((currency) => {
                      return (
                        <Dropdown.Item
                          key={currency}
                          onClick={() => {
                            this.setState({
                              collection_currency: currency,
                            });
                          }}
                        >
                          {currency}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </Card>
          )}
          {!this.state.allowAnyBalance && (
            <Card className="card-selection my-3 p-3">
              {this.renderCard(card)}
            </Card>
          )}
          <div className="my-3">
            {this.props.errors && (
              <MessageBlock
                message={this.props.errors}
                type={"error"}
                icon={<FiAlertCircle />}
              />
            )}
            {/* <button>ApplePay</button> */}
            <button
              className="pay-with-card col-12"
              onClick={() => {
                this.payWithCard();
              }}
              disabled={
                this.props.loading ||
                this.props.accountsLoading ||
                (!this.state.allowAnyBalance &&
                  !this.props.paymentCard &&
                  !this.props.pendingRatePurchase)
              }
            >
              {this.props.loading || this.props.accountsLoading ? (
                <div className="spinner-border text-dark m-auto" role="status">
                  <span className="sr-only">Loading...</span>
                </div>
              ) : (
                this.getButtonText()
              )}
            </button>
          </div>
        </Card>
      </div>
    );
  }
}

const mapStateToProps = ({
  userDetails,
  purchaseLabel,
  rateChoices,
  stores,
  applicationContext,
  wallets,
}) => ({
  defaultCard: userDetails.defaultCard,
  paymentCard: userDetails.paymentCard,
  cardList: userDetails.cards,
  loadCardList: userDetails.loading || userDetails.defaultLoading,
  info: userDetails.info,
  userEmail: purchaseLabel.userEmail,
  selectedRate: rateChoices.selectedRate,
  loading: purchaseLabel.loading,
  errors: purchaseLabel.errors,
  fileType: purchaseLabel.fileType,
  selectedOrder: stores.selectedOrder,
  returnURL: applicationContext.context.returnURL,
  authType: applicationContext.context.auth,
  pendingRatePurchase: purchaseLabel.label,
  accounts: wallets.accounts,
  accountsLoading: wallets.loading,
});

export default withRouter(
  connect(mapStateToProps, {
    selectCard,
    getUserDetails,
    purchaseRateCard,
    updatePurchaseEmail,
    updateFileType,
    clearApplicationContext,
    checkPendingRatePurchase,
    clearPurchaseStates,
    getAccounts,
  })(PurchaseLabel),
);
